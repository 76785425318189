import * as React from 'react';
import Box from '@mui/material/Box';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  Paper,
} from '@mui/material';

import SendIcon from '@mui/icons-material/Send';

interface Props {
  loading: boolean;
  value?: string;
  onSubmit: (value: string) => void;
}

export const BotInput: React.FC<Props> = ({
  loading,
  value = '',
  onSubmit,
}) => {
  const [question, setQuestion] = React.useState(value);

  React.useEffect(() => {
    if (value) {
      setQuestion(value);
    }
  }, [value]);

  const handleSubmitQuestion = (value: string) => () => {
    onSubmit(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      onSubmit(question);
    }
  };

  return (
    <Paper sx={{ mt: 'auto', width: '100%' }}>
      {loading && <LinearProgress />}
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="question">Question</InputLabel>
        <OutlinedInput
          id="question"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyDown={handleKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleSubmitQuestion(question)} edge="end">
                <SendIcon />
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
      </FormControl>
    </Paper>
  );
};
