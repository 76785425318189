import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';

interface Props extends React.PropsWithChildren {
  title: string;
  icon?: React.ReactElement;
}

export const TitleSection: React.FC<Props> = ({ title, icon, children }) => {
  if (!title) {
    return null;
  }

  return (
    <Box>
      <Stack direction="row" marginBottom={2} alignItems="center" gap={1}>
        {icon}
        <Typography variant="h6">{title}</Typography>
      </Stack>
      {children}
    </Box>
  );
};
