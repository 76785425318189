import * as React from 'react';
import { UserContext } from '../context/userContext';
import { promptService } from '../api';
import Box from '@mui/material/Box';
import { Toolbar } from '@mui/material';
import { BotInput } from '../molecules/BotInput';

export const Home: React.FC = () => {
  const { loggedIn } = React.useContext(UserContext);

  const { sendQuestion, questionIsPending } = promptService();

  return loggedIn ? (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        flexGrow: 1,
        p: 3,
      }}
    >
      <Toolbar />
      <BotInput
        loading={questionIsPending}
        onSubmit={(value) => {
          sendQuestion({ prompt: value });
        }}
      />
    </Box>
  ) : null;
};
