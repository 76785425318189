import * as React from 'react';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Theme,
  useTheme,
} from '@mui/material';

type OptionsType = string[];

interface Props {
  label: string;
  optionList?: OptionsType;
  selected?: OptionsType;
  onChange?: (selectedOptions: OptionsType) => void;
}

const getStyles = (
  name: string,
  selectedOptions: OptionsType,
  theme: Theme,
) => {
  return {
    fontWeight: selectedOptions.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultiSelect: React.FC<Props> = ({
  label,
  optionList,
  selected,
  onChange,
}) => {
  const id = label.replace(' ', '-');
  const theme = useTheme();
  const [selectedOptions, setSelectedOptions] = React.useState<OptionsType>([]);

  React.useEffect(() => {
    setSelectedOptions(selected || []);
  }, [selected]);

  const handleChange = ({
    target: { value },
  }: SelectChangeEvent<OptionsType>) => {
    // On autofill we get a stringified value.
    const selectedOptions =
      typeof value === 'string' ? value.split(',') : value;

    setSelectedOptions(selectedOptions);
    if (onChange) {
      onChange(selectedOptions);
    }
  };

  return (
    <Stack spacing={2}>
      <FormControl fullWidth>
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
        <Select
          labelId={`${id}-label`}
          id={id}
          fullWidth
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput id={id} label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {optionList?.map((option) => (
            <MenuItem
              key={option}
              value={option}
              style={getStyles(option, selectedOptions, theme)}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
