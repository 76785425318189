// https://elazizi.com/posts/react-query-auth-token-refresh/#simple-project-setup
import type { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';

import { api } from '../client';
import {
  ConversationItemRes,
  ConversationUidReq,
  ConversationUidRes,
  ConversationUidPromptReq,
  ConversationPromptRes,
  ConversationPromptReq,
} from '../types/';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { UserContext } from '../context/userContext';

const getConversationsList = () => {
  return api.get<AxiosError, ConversationItemRes>('/v1/conversation');
};

const getConversationUid = ({ uid }: ConversationUidReq) => {
  return api.get<AxiosError, ConversationUidRes>(`/v1/conversation/${uid}`);
};

const postQuestion = ({ uid, prompt }: ConversationUidPromptReq) => {
  return api.post<AxiosError, ConversationPromptRes>(
    `/v1/conversation/${uid}/prompt`,
    { prompt },
  );
};

const postQuestionCreateConversation = ({ prompt }: ConversationPromptReq) => {
  return api.post<AxiosError, ConversationPromptRes>(
    '/v1/conversation/prompt',
    { prompt },
  );
};

export const promptService = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const questionMutation = useMutation({
    mutationFn: postQuestionCreateConversation,
    onError: ({ message }) => {
      enqueueSnackbar(`postQuestion error: ${message}`, { variant: 'error' });
    },
    onSuccess: (res: ConversationPromptRes) => {
      navigate(`/conversation/${res.data.uid}`);
    },
  });

  return {
    questionIsPending: questionMutation.isPending,
    sendQuestion: questionMutation.mutate,
  };
};

export const conversationService = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { loggedIn } = React.useContext(UserContext);

  const conversationList = useQuery({
    queryKey: ['conversationList'],
    queryFn: getConversationsList,
    enabled: loggedIn,
  });

  const conversationMutation = useMutation({
    mutationFn: getConversationUid,
    onError: ({ message }) => {
      enqueueSnackbar(`getConversationUid error: ${message}`, {
        variant: 'error',
      });
    },
    onSuccess: () => {},
  });

  const questionMutation = useMutation({
    mutationFn: postQuestion,
    onError: ({ message }) => {
      enqueueSnackbar(`postQuestion error: ${message}`, { variant: 'error' });
    },
    onSuccess: () => {},
  });

  return {
    conversationList: conversationList.data?.data.conversations,
    conversationQasIsLoading: conversationList.isLoading,
    conversationQas: conversationMutation.data?.data.qas,
    conversationQasIsPending: conversationMutation.isPending,
    questionResponse: questionMutation.data?.data,
    questionIsPending: questionMutation.isPending,
    getConversationQas: conversationMutation.mutate,
    sendQuestion: questionMutation.mutate,
  };
};

// CHECK
// https://medium.com/@amavictor/how-to-use-react-query-axios-and-a-custom-request-processor-to-transform-your-data-2a9f0c9f5bf0
